import React, { useState, useEffect } from "react";
import UserService from "../services/userService";
import AdvisorService from "../services/advisorService";
import BrandService from "../services/brandService";
import { withOktaAuth } from "@okta/okta-react";
import Advisors from "./advisorsComponent/advisors";
import Portals from "./portals";
import Header from "./header";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import Footer from "./footer";
import LegalTermsconditions from "../components/LegalTermsconditions";
import UserInfo from "./userInfo";
import LogRocket from "logrocket";

import "./menu.css";
import spinner from "../images/ajax-loader.gif";
import InvoicePayLink from "./InvoicePayLink";

const Menu = (props) => {
  const [userId, setUserId] = useState();
  const [sessionId, setSessionId] = useState();
  const [userEmail, setUserEmail] = useState();
  const [portalLoading, setPortalLoading] = useState(true);
  const [headerLoading, setHeaderLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [userName, setUserName] = useState();
  const [isConsentAccepted, setIsConsentAccepted] = useState(true);
  const [brandPrefix, setBrandPrefix] = useState("hightower");
  const [advisor, setAdvisor] = useState();
  const history = useHistory();

  useEffect(() => {
    var domain = "";
    if (props.location.search.includes("domain")) {
      var params = props.location.search.split("=");
      domain = params[1];
      const brand = BrandService.getBrandClassPrefix(domain);
      setBrandPrefix(brand);
      props.setBackground(brand);
      sessionStorage.setItem("brandPrefix", brand);
    } else {
      var brandDomain = sessionStorage.getItem("brandPrefix");
      if (brandDomain !== null && brandDomain !== undefined) {
        var brandPrefix = BrandService.getBrandClassPrefix(brandDomain);
        setBrandPrefix(brandPrefix);
        props.setBackground(brandPrefix);
      } else {
        var host = window.location.host;
        var domains = host.split(".");
        domain = domains[0];
        const brand = BrandService.getBrandClassPrefix(domain);
        setBrandPrefix(brand);
        props.setBackground(brand);
        sessionStorage.setItem("brandPrefix", domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.oktaAuth.getUser().then((user) => {
      setUserId(user.sub);
      setUserEmail(user.email);

      props.oktaAuth.session.get().then((session) => {
        if (session.status === "INACTIVE") {
          history.push("/login");
        }
        setSessionId(session.id);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oktaAuth]);

  useEffect(() => {
    if (sessionId !== undefined && userId !== undefined) {
      UserService.getData(userId, sessionId)
        .then((result) => {
          if (result) {
            setUserInfo(result);
            setUserName(result.Name);
            if (result.isConsentAgreed !== "true") setIsConsentAccepted(false);
            LogRocket.identify(userId, {
              name: result.Name,
              email: userEmail,
            });
          }
        })
        .catch((error) => {
          //do nothing when the service return 404
        });
      setHeaderLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, sessionId]);

  useEffect(() => {
    var advisor = sessionStorage.getItem("advisor");
    if (advisor !== null || advisor === undefined) {
      setAdvisor(JSON.parse(sessionStorage.getItem("advisor")));
    } else {
      if (sessionId !== undefined && userId !== undefined) {
        AdvisorService.getData(userId, sessionId)
          .then((result) => {
            if (result) {
              setAdvisor(result);
            }
          })
          .catch((error) => {
            //do nothing when the service return 404
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, sessionId]);

  useEffect(() => {
    if (advisor) {
      ReactGA.set("Advisor Practice", advisor.AnalyticsTag);
    }
  }, [advisor]);

  useEffect(() => {
    if (!portalLoading && !headerLoading) {
      setLoading(false);
    }
  }, [portalLoading, headerLoading]);
  return (
    <>
      {userId && sessionId && (
        <>
          {!loading && <Header brandPrefix={brandPrefix} username={userName} />}
          {!isConsentAccepted && (
            <div>
              <LegalTermsconditions
                isConsentAccepted={isConsentAccepted}
                setIsConsentAccepted={setIsConsentAccepted}
                userId={userId}
                sessionId={sessionId}
                //setUserAgreement={setUserAgreement}
                setLoading={setLoading}
              />
            </div>
          )}
          {isConsentAccepted && (
            <div>
              {!loading && <InvoicePayLink userInfo={userInfo} />}
              {!loading && advisor && <Advisors advisorTeam={advisor} />}
              <Portals userInfo={userInfo} userId={userId} sessionId={sessionId} renderComplete={() => setPortalLoading(false)} />
              {!loading && userInfo && <UserInfo userInfo={userInfo} />}
            </div>
          )}
          {!loading && <Footer />}
        </>
      )}
      {loading && (
        <div className="spinner-background">
          <div className="container spinner-background">
            <div className="mx-auto d-block justify-content-center text-center mt-4">
              <h2>We're signing you into your portal...</h2>
            </div>
            <div className="mx-auto d-block justify-content-center text-center mt-3">
              <img src={spinner} alt="spinner" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withOktaAuth(Menu);
