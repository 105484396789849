import React from "react";

const PortalWide = props => {
    let logos = props.portal.LogoFile.split('/');
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card-horizontal">
                        <div className="row advisor-content align-items-center">
                            <div className="col-12 col-md-auto d-flex justify-content-center">
                                <img className="logo-wide" src={require(`../images/vendor-logos/${logos[logos.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
                            </div>
                            <div className="col-12 col-md">
                                <div className="row">
                                    <div className="col-12 d-md-none border-top mt-4" />
                                    <div className="card-body col-md-12 col-lg-8 ml-4">
                                        <ul className="pl-0 list-group d-flex ul-horizontal">
                                            {
                                                props.portal.Description.map((row) =>
                                                    <li className="text-secondary text-decoration-none" >{row}</li>
                                                )
                                            }
                                        </ul>

                                    </div>
                                    <div className="col d-flex justify-content-lg-end justify-content-center align-items-center font-weight-bold">
                                        <a href={props.portal.URL} target="_blank" rel="noreferrer" className="decoration-none account-link p-2">Open</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PortalWide;