import React, { useState, useEffect } from "react";
import MenuService from "../services/menuService";
import Portal from "./portal";
import PortalWide from "./portalWide";

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}

const Portals = (props) => {
  const { userId } = props;
  const [portals, setPortals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [colWidth, setColWidth] = useState(0);
  const [portalsInFirstRow, setPortalsInFirstRow] = useState([]);
  const [otherPortals, setOtherPortals] = useState([]);
  let width = useCurrentWidth();

  useEffect(() => {
    MenuService.getData(props.userId, props.sessionId).then(
      (result) => {
        if (result) {
          setPortals(result);
          props.renderComplete();
          setLoading(false);
        } else {
          props.setPageHeading("You don't have access.  Please contact your advisor practice.");
        }
      },
      (error) => {
        console.log(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResolution = (width) => {
    if (portals.length > 1) {
      if (width >= 738) {
        if (portals.length > 3) {
          setPortalsInFirstRow(portals.slice(0, 3));
          if (portals.length > 3) {
            setOtherPortals(portals.slice(3, portals.length));
          }
        } else {
          setPortalsInFirstRow(portals.slice(0, portals.length));
        }
      }
      if (440 <= width && width < 738) {
        if (portals.length > 2) {
          setPortalsInFirstRow(portals.slice(0, 2));
          setOtherPortals(portals.slice(2, portals.length));
        } else {
          setPortalsInFirstRow(portals.slice(0, portals.length));
        }
      }
      if (width < 440) {
        setPortalsInFirstRow(portals.slice(0, 1));
        setOtherPortals(portals.slice(1, portals.length));
      }
    }
  };

  useEffect(() => {
    handleResolution(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    if (portals) {
      portals.length === 2 ? setColWidth(6) : setColWidth(4);
      handleResolution(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals]);

  function showMore() {
    var button = document.getElementById("showMoreButton");
    if (button) {
      button.classList.add("d-none");
    }
    var container = document.getElementById("otherPortalsRowContainer");
    if (container) {
      container.classList.remove("d-none");
    }
    var showLessButton = document.getElementById("showLessButton");
    if (showLessButton) {
      showLessButton.classList.remove("d-none");
    }
  }

  function showLess() {
    var container = document.getElementById("otherPortalsRowContainer");
    if (container) {
      container.classList.add("d-none");
    }

    var showLessButton = document.getElementById("showLessButton");
    if (showLessButton) {
      showLessButton.classList.add("d-none");
    }

    var showMoreButton = document.getElementById("showMoreButton");
    if (showMoreButton) {
      showMoreButton.classList.remove("d-none");
    }
  }

  return (
    <>
      {!loading && colWidth > 0 && portals && portals.length > 0 ? (
        <div className="container fixed">
          {/* <div className="text-title"><span>Your Portals</span></div> */}
          {portals.length > 1 ? (
            <>
              <div className="row">
                {portalsInFirstRow &&
                  portalsInFirstRow.length > 0 &&
                  portalsInFirstRow.map((row, index) => <Portal userInfo={props.userInfo} userId={userId} key={index} portal={row} colWidth={colWidth} />)}
              </div>
              {otherPortals.length > 0 ? (
                <>
                  <div id="otherPortalsRowContainer" className="row d-none">
                    {otherPortals && otherPortals.length > 0 && otherPortals.map((row, index) => <Portal userInfo={props.userInfo} userId={userId} key={index} portal={row} colWidth={colWidth} />)}
                  </div>
                  <button id="showLessButton" type="button" className="btn btn-link d-none" onClick={showLess}>
                    Show Less
                  </button>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="row">
              <PortalWide portal={portals[0]} />
            </div>
          )}
          {otherPortals.length > 0 ? (
            <button id="showMoreButton" type="button" className="btn btn-link" onClick={showMore}>
              Show More
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Portals;
