import axios from "axios";

axios.interceptors.response.use(null, (error) => {
  if (!error.response) {
    window.location.href = window.location.origin + "/error";
  }

  const expectedError = error.response && error.response.status >= 400 && error.response.status <= 599 && error.response.status !== 404;

  if (expectedError) {
    window.location.href = window.location.origin + "/error";
  }

  if (!expectedError) {
    console.error(error);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  config.timeout = 25000;
  return config;
});

const service = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default service;
