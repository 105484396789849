import http from "./httpService";

class LegalTermsService {
  static getLegalTerms() {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/legal-terms";
      http
        .get(endpoint)
        .then((response) => {
          const respData = response.data;
          resolve(respData);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return myPromise;
  }
}


export default LegalTermsService;
