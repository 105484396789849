import http from "./httpService";

class MenuService {
  static getData(userId, sessionId) {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/users/" + userId + "/menuItemsV2";


      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
          
          var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
          const getUserAccessToken = getUserToken.accessToken;
          const updatedUserToken = getUserAccessToken.accessToken;

      http.get(endpoint, {headers: { 
        'token': updatedUserToken,
        'client_id': clientId

     }}).then(response => {
        const items = response.data;
        resolve(items);
      })
    });
    return myPromise;
  }
}

export default MenuService;
