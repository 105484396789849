import React from "react";
import help from "../help/well-th-view-user-guide.pdf";
import "./footer.css";

const Footer = (props) => {
  const appVersion = process.env.REACT_APP_VERSION;

  return (
    <div className="row justify-content-center footer footer-font-size border-top mb-4">
      <div className="col-12 mt-2">
        <div>
          <ul className="footer-menu">
<li>
<a href="https://hightoweradvisors.com/legal-and-privacy.html" target="_blank" rel="noreferrer">
              Legal {"&"} Privacy
            </a>
</li>
<li>
<a href="legalTerms" target="_blank" rel="noreferrer">
              Legal Terms Of Use
            </a>
</li>
<li>
<a href="https://hightoweradvisors.com/web-accessibility-policy.html" target="_blank" rel="noreferrer">
              Web Accessibility
            </a>
</li>
<li>
<a href={help} target="_blank" rel="noreferrer">
              Help
            </a>
</li>
          </ul>
            
          
    
        
        </div>
        <div className="text-center footer-content">
          Securities offered through Hightower Securities, LLC, Member FINRA/SIPC,&nbsp; 
          <a href="https://hightoweradvisors.com/" target="_blank" rel="noreferrer">
            Hightower Advisors, LLC
          </a>
          &nbsp; is a SEC registered <br /> investment adviser, &nbsp;
          <a href="https://brokercheck.finra.org/" target="_blank" rel="noreferrer">
          brokercheck.finra.org
          </a>
          <br />
        </div>
        <div className="text-center footer-content">Version {appVersion}. ©️2021 Hightower Advisors. All Rights Reserved.</div>
      </div>
    </div>
  );
};

export default Footer;
