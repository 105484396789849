import { useAccordionButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const CustomBackToggle = (props) => {
  const { eventKey, handleCollapse, backBtnRef } = props;
  const handleBackBtn = useAccordionButton(eventKey, () => handleCollapse());

  return (
    <Button ref={backBtnRef} style={{ display: "none" }} onClick={handleBackBtn} class="btn-home" id="button">
      Back
    </Button>
  );
};

export default CustomBackToggle;
