import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./InvoicePayLink.css";

function InvocePayLink({ userInfo }) {
  const [showPopup, setShowPopu] = useState(false);

  const toggleShow = () => {
    setShowPopu(!showPopup);
  };
  if(showPopup) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  return (
    <>
      {!userInfo?.ShowPayInvoice && (
          <div className="container">
            <div className="invoice">
              <Link onClick={toggleShow} to="#" className="invoice-link">
                Click here to pay invoice
              </Link>
            </div>
          </div>
        )}
        {showPopup && (
        <div>
          <div onClick={toggleShow} className="overlay"></div>
          <div  className="modal-content">
            <ul>
              <li>You are being Redirected to make a payment online using your banking instructions.</li>
              <li>Your invoice group number and phone number associated with your account(s) will be required to login.</li>
              <li>If you have already made your payment or have set up auto payments online, payment is not due.</li>
            </ul>
            <a className="onlinepay" href="https://onlinepay.hightoweradvisors.com">
              Continue
            </a>
          </div>
        </div>
      )}
    </>
  );
}
export default InvocePayLink;
