import React, { useState, useEffect } from "react";
import AdvisorsList from "./advisorsList";
import "./advisors.css";
import AdvisorWide from "./advisorWide";
//import advisorMockedData from "../../services/advisorMockedData"

const Advisors = (props) => {
  const [advisorsData, setAdvisorsData] = useState();
  const [visible, setVisible] = useState(3);
  const [showMoreAdvisors, setShowMoreAdvisors] = useState(false);
  const [showContactDetail, setShowContactDetail] = useState("");
  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    setVisible(showMoreAdvisors ? advisorsData?.Advisors?.length : size < 440 ? 1 : size >= 440 && size <= 767 ? 2 : 3);
  }, [showMoreAdvisors, advisorsData, showContactDetail, size]);

  const taggleShowAdvisors = () => {
    setShowMoreAdvisors(!showMoreAdvisors);
  };

  useEffect(() => {
    if (props.advisorTeam !== null || props.advisorTeam === undefined) {
      setAdvisorsData(props.advisorTeam);
      //setAdvisorsData(advisorMockedData)
      const handleSize = () => setSize(window.innerWidth);
      window.addEventListener("resize", handleSize);
      return () => window.removeEventListener("resize", handleSize);
    }
  }, [props.advisorTeam]);

  const showAdvisorDetial = (id) => {
    setShowContactDetail("");
    advisorsData.Advisors.filter((advisor, index) => advisor.Id + index === id && showContactDetail === "" && setShowContactDetail(advisor));
  };
  const hideAdvisorDetial = (id) => {
    advisorsData.Advisors.filter((advisor, index) => {
      return advisor.Id + index === id && showContactDetail && setShowContactDetail("");
    });
  };
  return advisorsData ? (
    <>
      <div className="container">
        <div className="text-title">
          <span>{advisorsData.Firm}</span>
        </div>
        <div>
          <img className="contact-icon" src={require("../../images/clientPortal/Address.png").default} alt="Advisor's Address Icon" />
          <span>
            <a
              className="contact"
              target="_blank"
              rel="noreferrer"
              href={encodeURI(`http://maps.google.com/?q=${advisorsData.StreetAddress.replace(" ", "+")},+${advisorsData.City}+,${advisorsData.State}+${advisorsData.PostalCode}`)}
            >
              {advisorsData.StreetAddress}, {advisorsData.City}, {advisorsData.State} {advisorsData.PostalCode}
            </a>
          </span>
        </div>
      </div>
      <div className="container gap">
        {advisorsData?.Advisors?.length === 1 ? (
          <AdvisorWide advisor={advisorsData.Advisors[0]} />
        ) : (
          <div className={size < 460 ? "grid-1" : size >= 460 && size <= 767 ? "grid-2" : advisorsData?.Advisors?.length === 2 ? "grid-2-fit" : "grid"}>
            {/* <div className="row"> */}
            {advisorsData &&
              advisorsData.Advisors?.slice(0, visible).map((advisor, index) => (
                <AdvisorsList
                  key={advisor.Id + index}
                  advisor={advisor}
                  uid={advisor.Id + index}
                  size={size}
                  numAdvisors={advisorsData?.Advisors?.length}
                  showContactDetail={showContactDetail}
                  hideAdvisorDetial={hideAdvisorDetial}
                  showAdvisorDetial={showAdvisorDetial}
                />
              ))}
          </div>
        )}
        {(advisorsData?.Advisors?.length > 1 && size < 440) || (advisorsData?.Advisors?.length > 2 && size > 440 && size < 738) || advisorsData?.Advisors?.length > 3 ? (
          <button className=" btn btn-link btn-gap" onClick={taggleShowAdvisors}>
            {showMoreAdvisors ? "Show Less" : "Show More"}
          </button>
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default Advisors;
