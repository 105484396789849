import React, { useState, useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import { useHistory } from "react-router-dom";
import "./legalTermsPopup.css";
import { useEffect } from "react";
import http from "../services/httpService";
import LegalTermsService from "../services/legalTermsService";
import UserService from "../services/userService";
import ConsentLogService from "../services/consentLogService";
import { useOktaAuth } from "@okta/okta-react";

const LegalTermsconditions = (props) => {
  const [value, setValue] = useState(true);
  const [legalterms, setLegalterms] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  // const userInfo = useState({});
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const { setLoading } = props;
  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    http
      // .get("https://bit.ly/3eAdjXV")
      // .get("https://api.ipify.org")
      .get({
        country_code: "US",
        country: "India",
        ipaddress: "27.57.192.95",
      })

      .then((response) => {
        setUserInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSignOut = async () => {
    sessionStorage.clear();
    oktaAuth.tokenManager.clear();
    await oktaAuth
      .signOut()
      .then(function () {
        history.push({ pathname: "/login" });
      })
      .catch(function (err) {});
  };
  const handleAgreedOrCancel = (isAgreed = true) => {
    UserService.updateData(props.userId, props.sessionId, isAgreed)
      .then((resData) => {
        ConsentLogService.getConsentLog(props.userId, props.sessionId, userInfo, isAgreed, legalterms.versionNumber)
          .then((resData) => {
            isAgreed ? props.setIsConsentAccepted(true) : props.setIsConsentAccepted(false);
            //isAgreed ? props.setUserAgreement(true) : props.setUserAgreement(false);
            props.setLoading(false);
            isAgreed ? history.push("/menu") : onSignOut();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    LegalTermsService.getLegalTerms()
      .then((resData) => {
        setLegalterms(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const legaltermsText = legalterms && legalterms.articleConsent;

  const listInnerRef = useRef();
  const onScroll = (e) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      let ScollTopClientHeight = scrollTop + clientHeight;

      let floorScollTopClientHeight = Math.floor(scrollTop + clientHeight);

      let ceilScollTopClientHeight = Math.ceil(scrollTop + clientHeight);

      if (ScollTopClientHeight === scrollHeight || floorScollTopClientHeight === scrollHeight || ceilScollTopClientHeight === scrollHeight) {
        setValue(false);
      }
    }
  };
  const iAgreeBtnClass = value ? "secondary" : "success";
  return (
    <>
      <div>
        <div show={!props.isConsentAccepted}>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-lg-6 col-md-9 drop-shadow mt-5 p-3">
                <h2>Terms Of Use</h2>

                <div className="element-container default ht-gray-stripe-fill min-height-25"></div>

                <div>
                  <div onScroll={onScroll} ref={listInnerRef} style={{ height: 240, fontSize: "20px", overflowY: "auto", marginTop: 20 }} dangerouslySetInnerHTML={{ __html: legaltermsText }} />
                </div>
                <div className="footer">
                  <Button variant={iAgreeBtnClass} disabled={value} className="p-btn" onClick={() => handleAgreedOrCancel()}>
                    I Agree
                  </Button>
                  <Button variant="secondary p-btn" onClick={() => handleAgreedOrCancel(false)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LegalTermsconditions;
