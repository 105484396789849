export const tableHeadings = {
  name: "Name",
  account: "Account",
  startdate: "Start Date",
  enddate: "End Date",
  pageCount: "Page Count",
  dateposted: "Date Posted",
};
export const tableBody = [
  {
    name: "<span class='bi bi-file-earmark-fill file-icon'></span> consector Adispacing",
    account: "mark",
    quater: "q1",
    startdate: "10/23/2023",
    enddate: "10/23/2023",
    filesize: "10mb",
    dateposted: `10/23/2023
        <span class="bi bi-eye-fill eye-icon"></span>
        <img class="download-icon" src="https://img.icons8.com/material-rounded/24/000000/download--v1.png" />
    `,
  },
  {
    name: "<span class='bi bi-file-earmark-fill file-icon'></span> consector Adispacing",
    account: "jacob",
    startdate: "10/23/2023",
    enddate: "10/23/2023",
    quater: "q1",
    filesize: "10mb",
    dateposted: `10/23/2023
        <span class="bi bi-eye-fill eye-icon"></span>
        <img class="download-icon" src="https://img.icons8.com/material-rounded/24/000000/download--v1.png" />
    `,
  },
  {
    name: "<span class='bi bi-file-earmark-fill file-icon'></span> consector Adispacing",
    account: "mark",
    startdate: "10/23/2022",
    enddate: "10/23/2022",
    filesize: "10mb",
    quater: "q2",
    dateposted: `10/23/2022
        <span class="bi bi-eye-fill eye-icon"></span>
        <img class="download-icon" src="https://img.icons8.com/material-rounded/24/000000/download--v1.png" />
    `,
  },
  {
    name: "<span class='bi bi-file-earmark-fill file-icon'></span> consector Adispacing",
    account: "jacob",
    startdate: "10/23/2023",
    enddate: "10/23/2023",
    filesize: "10mb",
    quater: "q1",
    dateposted: `10/23/2022
        <span class="bi bi-eye-fill eye-icon"></span>
        <img class="download-icon" src="https://img.icons8.com/material-rounded/24/000000/download--v1.png" />
    `,
  },
  {
    name: "<span class='bi bi-file-earmark-fill file-icon'></span> consector Adispacing",
    account: "mark",
    startdate: "10/23/2023",
    enddate: "10/23/2023",
    filesize: "10mb",
    quater: "q3",
    dateposted: `10/23/2024
        <span class="bi bi-eye-fill eye-icon"></span>
        <img class="download-icon" src="https://img.icons8.com/material-rounded/24/000000/download--v1.png" />
    `,
  },
  {
    name: "<span class='bi bi-file-earmark-fill file-icon'></span> consector Adispacing",
    account: "jacob",
    startdate: "10/23/2023",
    enddate: "10/23/2023",
    filesize: "10mb",
    quater: "q1",
    dateposted: `10/23/2022
        <span class="bi bi-eye-fill eye-icon"></span>
        <img class="download-icon" src="https://img.icons8.com/material-rounded/24/000000/download--v1.png" />
    `,
  },
];
