import http from "./httpService";
class PortfolioRepostsService {
  static getReports(bdportalID, bdID) {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = "https://ht-client-process-api-dev-v1.us-e2.cloudhub.io/api/portfolio/" + bdID + "/" + bdportalID;

      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
      var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"));
      const getUserAccessToken = getUserToken.accessToken;
      const updatedUserToken = getUserAccessToken.accessToken;

      http
        .get(endpoint, {
          headers: {
            token: updatedUserToken,
            client_id: clientId,
          },
        })
        .then((response) => {
          const respData = response.data;
          resolve(respData);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return myPromise;
  }
}
export default PortfolioRepostsService;
