import http from "./httpService";
const detectBrowser = () => {
  let agent = window.navigator.userAgent.toLocaleLowerCase();
  switch (true) {
    case agent.indexOf("edge") > -1:
      return "MS Edge (EdgeHtml)";
    case agent.indexOf("edg") > -1:
      return "MS Edge Chromium";
    case agent.indexOf("opr") > -1 && !!window.opr:
      return "opera";
    case agent.indexOf("chrome") > -1 && !!window.chrome:
      return "chrome";
    case agent.indexOf("trident") > -1:
      return "Internet Explorer";
    case agent.indexOf("firefox") > -1:
      return "firefox";
    case agent.indexOf("safari") > -1:
      return "safari";
    default:
      return "other";
  }
};
// get device type
const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|ip(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};
class ConsentLogService {
  static getConsentLog(userId, sessionId, userInfo, isAgreed, versionNumber) {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/consent-log";



      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
          
          var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
          const getUserAccessToken = getUserToken.accessToken;
          const updatedUserToken = getUserAccessToken.accessToken;



      // const data = {
      //   browsertype: detectBrowser(),
      //   userId: userId,
      //   consentagreed: isAgreed,
      //   country: userInfo.data.country_code,
      //   ipaddress: userInfo.data.IPv4,
      //   devicetype: getDeviceType(),
      //   consentversion: versionNumber,
      // };
      const data = {
        browsertype: detectBrowser(),
        userId: userId,
        consentagreed: isAgreed,
        country: "US",
        ipaddress: "27.57.192.95",
        devicetype: getDeviceType(),
        consentversion: versionNumber,
      };
      http
        .post(endpoint, data, { headers: { 
              'token': updatedUserToken,
              'client_id': clientId

           } })
        .then((response) => {
          const respData = response.data;
          resolve(respData);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return myPromise;
  }
}
export default ConsentLogService;
