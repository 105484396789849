import http from "./httpService";



  class AdvisorService {
      static getData(userId, sessionId) {
        const myPromise = new Promise((resolve, reject) => {
          let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/users/" + userId + "/advisorTeam";
          let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
          
          var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
          const getUserAccessToken = getUserToken.accessToken;
          const updatedUserToken = getUserAccessToken.accessToken;
          

          http.get(endpoint, {headers: 
            { 
              'token': updatedUserToken,
              'client_id': clientId

           }}).then(response => {
            var advisorData = response.data;
            resolve(advisorData);
          })
          .catch(error => {
            reject(error);
          });
        });
        return myPromise;
      }
    }

  export default AdvisorService;