import React from "react";
import { withOktaAuth } from "@okta/okta-react";
import Footer from "./footer";

const Error = props => {

  function logout() {
    props.oktaAuth
      .signOut()
      .then(function () {
        props.oktaAuth.tokenManager.clear();
        sessionStorage.clear();
      })
      .catch(function (err) {
        console.error(err);
      })
  }

  return (
    <>
      <div className="row justify-content-center alert alert-background alert-error">
        <div className="col-6-md text-center">
          <h4>Oops! something went wrong.</h4>
          <button className="btn btn-link sign-out" onClick={logout}>Please try again.</button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withOktaAuth(Error);
