// import { useEffect } from "react";
// import { useOktaAuth } from "@okta/okta-react";

import "./login.css";
import Footer from "./footer";

const Logout = props => {
    // const { oktaAuth } = useOktaAuth();
    // useEffect(() => {
    //     oktaAuth.tokenManager.clear();
    //     oktaAuth
    //         .signOut()            
    //         .catch(function (err) {
    //             console.error(err);
    //         })
    //         .then(function () {
    //             // redirect to root
    //             window.href = window.location.origin;
    //         });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <>
            <div className="container main-container pt-5">
                <div className="row justify-content-center">
                    <h1 className="h1-font-size">Your session has ended</h1>
                    <div className="w-100"></div>
                    <div className="text-muted">Please close this browser window</div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Logout;