import React, { useEffect, useState } from "react";
import BrandService from "../services/brandService";

function BrandLogo(props) {
  const [brandPrefix, setBrandPrefix] = useState("");
  useEffect(() => {
    var domain = "";
    if (props.location.search.includes("domain")) {
      var params = props.location.search.split("=");
      domain = params[1];
    } else {
      var host = window.location.host;
      var domains = host.split(".");
      domain = domains[0];
    }
    const brand = BrandService.getBrandClassPrefix(domain);
    setBrandPrefix(brand);
  }, [props.location.search]);

  return (
    <>
      {brandPrefix && brandPrefix !== "hightower" && (
          <img className="img-fluid" src={require(`../images/${brandPrefix}-logo.png`).default} alt="Team Brand Logo" />
      )}
    </>
  );
}

export default BrandLogo;
