class BrandService {
  static getBrandClassPrefix(host) {
    var domain = host;
    
    let defaultPrefix = "hightower";

    let prefixes = {
      "synergycapitalsolutions": "synergy",
      "falconwealthadvisors": "falcon",
      "alinewealth": "aline",
      "treasurypartners": "tp",
      "morgiawm": "morgia",
      "harveyinvestmentmanagement": "harvey",
      "thebahnsengroup": "tbg",
      "fortresswealthplanning": "fortress",
      "cogneticcapital": "cognetic",
      "hummermower": "hma",
      "somersetadvisory": "somerset",
      "lexingtonwealth": "lexington",
      "irongatepartnersinc": "irongate",
      "dsgadvisors": "dsg",
      "abc": "abc",
      "schultzcollins": "schultz",
      "stearnsfinancial": "stearns",
      "lourdmurray": "lourd",
      "investsg":"isg",
      "argent":"argent",
      "teaktreecapital":"teaktree",
      "bluerock":"bluerock",
      "theandriolegroup":"andriolegroup",
      "greensquareco":"greensquare",
      "rikoongroup":"rikoon",
      "clearperspectiveadvisors":"clearperspective",
      "fairportwealth":"fairport"
    }
 
    for (const key in prefixes) {
      if (domain.includes(key)) {
        return prefixes[key];
      }
    }

    //return 'dsg';
    return defaultPrefix;
  }
}

export default BrandService;
