import React from "react";

const UserInfo = (props) => {
  return (
    <>
      {props && props.userInfo && props.userInfo.ShowUserInformation ? (
        <>
          <div className="container mt-5">
            <div className="text-title">
              <span>Your Information</span>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card-horizontal">
                  <div className="row advisor-content align-items-center">
                    <div className="col">
                      <div className="d-flex flex-column container">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <span className="advisorName">{props.userInfo.Name}</span>
                          </div>
                        </div>
                        <div className="row userInfo align-items-center">
                          {props.userInfo.MailingAddress1 !== undefined && props.userInfo.MailingAddress1 !== "" && (
                            <div className="col-auto">
                              <div className="userInfo-row">
                                <img className="addressIcon" src={require("../images/clientPortal/user/Address.png").default} alt="Your Address Icon" />
                                <span>
                                  <b>Address</b>
                                </span>
                              </div>
                              <div>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  className="contact-user"
                                  href={encodeURI(
                                    `http://maps.google.com/?q=${props.userInfo.MailingAddress1},+${props.userInfo.MailingCity},+${props.userInfo.MailingState},+${props.userInfo.MailingPostalCode}`
                                  )}
                                >
                                  {props.userInfo.MailingAddress1}, {props.userInfo.MailingCity}, {props.userInfo.MailingState}, {props.userInfo.MailingPostalCode}
                                </a>
                              </div>
                            </div>
                          )}
                          {props.userInfo.Email !== undefined && props.userInfo.Email !== "" && (
                            <div className="col-auto">
                              <div className="userInfo-row email-text">
                                <img className="emailIcon" src={require("../images/clientPortal/user/Email.png").default} alt="Your Email Icon" />
                                <span>
                                  <b>Email Address</b>
                                </span>
                              </div>
                              <div>{props.userInfo.Email}</div>
                            </div>
                          )}
                          {props.userInfo.Phone !== undefined && props.userInfo.Phone !== "" && (
                            <div className="col-auto">
                              <div className="userInfo-row">
                                <img className="phoneIcon" src={require("../images/clientPortal/user/Phone.png").default} alt="Your Phone Icon" />
                                <span>
                                  <b>Phone Number</b>
                                </span>
                              </div>
                              <div>{props.userInfo.Phone}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserInfo;
