import React, { useState, useEffect } from "react";
import { withOktaAuth } from "@okta/okta-react";
import LegalTermsService from "../services/legalTermsService";
import HTLogo from "../images/hightower-logo.png";
import Footer from "./footer";
import "./LegalTerms.css";

const LegalTerms = () => {
  const [legalterms, setLegalterms] = useState([]);

  useEffect(() => {
    document.title = "Legal Terms of Use";
    LegalTermsService.getLegalTerms()
      .then((resData) => {
        setLegalterms(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const legaltermsText = legalterms && legalterms.articleConsent;
  return (
    <>
      <div className="container mt-4 mb-4">
        <img src={HTLogo} height="80" alt="Hihgh Tower" />
      </div>
      <div className="container-fluid p-0">
        <div className="container-fluid element-container default ht-gray-stripe-fill min-height-50 none"></div>
      </div>
      <div className="container">
        <h2 className="main-head">Terms Of Use</h2>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: legaltermsText }} />
      </div>
      <Footer />
    </>
  );
};

export default withOktaAuth(LegalTerms);
