import React from "react";
import "./cookiesInfo.css";
import Footer from "./footer";

const CookiesInfo = () => {
    return (
        <>
            <div className="cookies">
                <div className="container container-cookies-info">
                    <div className="title-info">Determine your web browser</div>
                    <div className="note">NOTE: &nbsp;Internet Explorer is not supported. &nbsp;Please use one of the supported browsers listed below.</div>
                    <div className="title-info">Google Chrome</div>
                    <div className="subtitle">To enable cookies in Google Chrome (PC):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Select the Chrome menu icon.</li>
                        <li className="list-group-info-item">Select Settings.</li>
                        <li className="list-group-info-item">Near the bottom of the page, select Show advanced settings.</li>
                        <li className="list-group-info-item">In the “Privacy” section, select Content settings.</li>
                        <li className="list-group-info-item">Select Allow local data to be set (recommended).</li>
                        <li className="list-group-info-item">Select Done.</li>
                    </ol>
                    <div className="subtitle">To enable cookies in Google Chrome (Mac):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Open Chrome preferences click on Settings, then Show Advanced Settings.</li>
                        <li className="list-group-info-item">Under Privacy, click on Content Settings.</li>
                        <li className="list-group-info-item">Make sure “Block third-party cookies and site data” is not checked.</li>
                        <li className="list-group-info-item">If your browser is not listed above, please refer to your browser’s help pages.</li>
                    </ol>
                    <div className="subtitle">To enable cookies in Google Chrome (Android):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">On your Android device, open the Chrome app.</li>
                        <li className="list-group-info-item">At the top right, tap More, More and then Settings.</li>
                        <li className="list-group-info-item">Tap Site settings and then Cookies.</li>
                        <li className="list-group-info-item">Next to “Cookies,” switch the setting on.</li>
                        <li className="list-group-info-item">To allow third-party cookies, check the box next to “Allow third-party cookies.”</li>
                    </ol>
                    <div className="title-info">Safari</div>
                    <div className="subtitle">To enable cookies in Safari (Mac):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Go to the Safari drop-down menu.</li>
                        <li className="list-group-info-item">Select Preferences.</li>
                        <li className="list-group-info-item">Click Privacy in the top panel.</li>
                        <li className="list-group-info-item">Under ‘Block cookies’ select the option ‘Never.’</li>
                        <li className="list-group-info-item">For increased security, once you have finished using the site, please change the Privacy setting back to Always.</li>
                    </ol>
                    <div className="subtitle">To enable cookies in Safari (iPhone/iPad iOS 11):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Open your Settings.</li>
                        <li className="list-group-info-item">Scroll down and select Safari.</li>
                        <li className="list-group-info-item">Under Privacy & Security, turn off “Prevent Cross-Site Tracking” and “Block All Cookies”.</li>
                    </ol>
                    <div className="title-info">Mozilla Firefox</div>
                    <div className="subtitle">To enable cookies in Mozilla Firefox 3.x (PC):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Click Tools {'>'} Options.</li>
                        <li className="list-group-info-item">Click Privacy in the top panel.</li>
                        <li className="list-group-info-item">Select the checkbox labeled ‘Accept cookies from sites.’</li>
                        <li className="list-group-info-item">Select the checkbox labeled ‘Accept third-party cookies.’</li>
                        <li className="list-group-info-item">Click OK.</li>
                    </ol>
                    <div className="subtitle">To enable cookies in Mozilla Firefox 2.x (PC):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Click Tools {'>'} Options.</li>
                        <li className="list-group-info-item">Click Privacy in the top panel.</li>
                        <li className="list-group-info-item">Select the checkbox labeled ‘Accept cookies from sites.’</li>
                        <li className="list-group-info-item">Click OK.</li>
                    </ol>
                    <div className="subtitle">To enable cookies in Mozilla Firefox 1.x (PC):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Click Tools{'>'} Options.</li>
                        <li className="list-group-info-item">Click Privacy in the top panel.</li>
                        <li className="list-group-info-item">Click the Cookies tab.</li>
                        <li className="list-group-info-item">Select the checkbox labeled ‘Allow sites to set cookies.’</li>
                        <li className="list-group-info-item">Click OK.</li>
                    </ol>
                    <div className="subtitle">To enable cookies in Mozilla Firefox (Mac):</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Go to the Firefox drop-down menu.</li>
                        <li className="list-group-info-item">Select Preferences.</li>
                        <li className="list-group-info-item">Click Privacy.</li>
                        <li className="list-group-info-item">Under Cookies, select the option ‘Accept cookies from sites.’</li>
                    </ol>
                    <div className="title-info">Edge</div>
                    <div className="subtitle">Allow or Block Cookies in the new Microsoft Edge:</div>
                    <ol className="list-group-info">
                        <li className="list-group-info-item">Open Microsoft Edge, select Menu (3 dots icon on top right corner of the browser) {'>'} Settings {'>'} Cookies and Site permissions {'>'} Cookies and Data stored {'>'} Manage and delete cookies and site data</li>
                        <li className="list-group-info-item">Turn on "Allow sites to save and read cookie data (recommended)"</li>
                        <li className="list-group-info-item">Turn off "Block third-party cookies"</li>
                    </ol>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default CookiesInfo;