import { useEffect, useState } from "react";

import "./AccordionComponent.css";

const PdfPreview = (props) => {
  const { setShowPDF, pdfUrl, pdfFileName } = props;
  const [url, setUrl] = useState();

  useEffect(() => {
    setUrl(window.URL.createObjectURL(pdfUrl));
  }, [pdfUrl]);

  const handleDownloadPDFBtn = () => {
    const url = window.URL.createObjectURL(new Blob([pdfUrl]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${pdfFileName}.pdf`);

    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <div className="pdf-viewer-container">
        <div className="pdf-viewer-preview" id="pdf-viewer">
          <div className="button-container">
            <div className="button-align">
              <button className="download-button" onClick={() => handleDownloadPDFBtn()}>
                Download
              </button>
              <button className="download-button" onClick={() => setShowPDF(false)}>
                Close
              </button>
            </div>
          </div>
          <div className="iframe-container">
            <iframe src={url + "#toolbar=0"} title="pdfview" type="application/pdf" width="80%" height="100%"></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfPreview;
