import { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import BrandService from "../../services/advisorService";
import UserService from "../../services/userService";
import AdvisorService from "../../services/advisorService";
import ReactGA from "react-ga";
import { withOktaAuth } from "@okta/okta-react";
import PortfolioRepostsService from "../../services/PortfolioRepostsService";
import PdfReportsService from "../../services/PdfReportsService";
import Form from "react-bootstrap/Form";
import Header from "../header";
import { tableHeadings } from "../../lib/constants";
import vaultImages from "../../images/vendor-logos/vault.svg";
import { MdOutlineFolderOpen } from "react-icons/md";
import { BsBoxArrowUpRight, BsFileEarmarkFill, BsFillEyeFill, BsFilterLeft } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import Footer from "../footer";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import "./AccordionComponent.css";
import Pagination from "react-custom-pagination";
import spinner from "../../images/ajax-loader.gif";
import PdfPreview from "./pdfPreview";
import CustomBackToggle from "./CustomBackToggle";
import Spinner from "react-bootstrap/Spinner";

const AccordionComponent = (props) => {
  const [userId, setUserId] = useState();
  const [sessionId, setSessionId] = useState();
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState();
  const [userName, setUserName] = useState();
  const [brandPrefix, setBrandPrefix] = useState("hightower");
  const [advisor, setAdvisor] = useState();
  const [performance, setPerformance] = useState(false);
  const [showFilter, setShowFilter] = useState();
  const [bdUserId, setBdUserId] = useState();
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const [reportsResponseData, setReportsResponseData] = useState([]);
  const [reports, setReports] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  const history = useHistory();
  const [showPDF, setShowPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  const [filterData, setFilterData] = useState({
    year: "",
    quarter: "",
  });
  /*-------------- Pagination Code begin here **************************/
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(2);
  let backBtnRef = useRef();

  //get current Posts

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = reports.slice(indexOfFirstPost, indexOfLastPost);

  // when user clicks on number this function will execute

  const paginate = (number) => {
    setCurrentPage(number);
  };
  /*-------------- Pagination Code end here **************************/
  useEffect(() => {
    var domain = "";
    if (props.location.search.includes("domain")) {
      var params = props.location.search.split("=");
      domain = params[1];
      const brand = BrandService.getBrandClassPrefix(domain);
      setBrandPrefix(brand);
      props.setBackground(brand);
      sessionStorage.setItem("brandPrefix", brand);
    } else {
      var brandDomain = sessionStorage.getItem("brandPrefix");
      if (brandDomain !== null && brandDomain !== undefined) {
        setBrandPrefix(brandPrefix);
      } else {
        var host = window.location.host;
        var domains = host.split(".");
        domain = domains[0];
        const brand = BrandService.getBrandClassPrefix(domain);
        setBrandPrefix(brand);
        props.setBackground(brand);
        sessionStorage.setItem("brandPrefix", domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    props.oktaAuth.getUser().then((user) => {
      setUserId(user.sub);
      props.oktaAuth.session.get().then((session) => {
        if (session.status === "INACTIVE") {
          history.push("/login");
        }
        setSessionId(session.id);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oktaAuth]);

  useEffect(() => {
    if (sessionId !== undefined && userId !== undefined) {
      UserService.getData(userId, sessionId)
        .then((result) => {
          const bdportalID = result.BDPortalUserId;
          const bdID = result.Id;
          setBdUserId(bdID);
          setUserName(result.Name);

          PortfolioRepostsService.getReports(bdportalID, bdID)
            .then((resData) => {
              setReports(resData.reports);
              setReportsResponseData(resData.reports);
              setLoading(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId, sessionId]);

  useEffect(() => {
    var advisor = sessionStorage.getItem("advisor");
    if (advisor !== null || advisor === undefined) {
      setAdvisor(JSON.parse(sessionStorage.getItem("advisor")));
    } else {
      if (sessionId !== undefined && userId !== undefined) {
        AdvisorService.getData(userId, sessionId)
          .then((result) => {
            if (result) {
              setAdvisor(result);
            }
          })
          .catch((error) => {
            //do nothing when the service return 404
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, sessionId]);
  useEffect(() => {
    if (advisor) {
      ReactGA.set("Advisor Practice", advisor.AnalyticsTag);
    }
  }, [advisor]);

  useEffect(() => {
    if (!!showFilter) {
      const getQuarter = (value) => {
        let date = new Date(value);
        const quaterValue = Math.floor(date.getMonth() / 3 + 1);
        if (quaterValue === 1) return "q1";
        else if (quaterValue === 2) return "q2";
        else if (quaterValue === 3) return "q3";
        else if (quaterValue === 4) return "q4";
      };

      const getTotalYears = (value) => {
        let date = new Date(value);
        return date.getFullYear();
      };

      const filteredData = reportsResponseData.filter(
        (item) =>
          getQuarter(item.createdDate) === (!!filterData?.quarter ? filterData?.quarter : "q1") &&
          getTotalYears(item.createdDate).toString() === (!!filterData?.year ? filterData?.year : uniqueYears[0].toString())
      );

      setReports(filteredData);
    }
  }, [reportsResponseData, filterData, showFilter, uniqueYears]);

  useEffect(() => {
    if (!showFilter) {
      setReports(reportsResponseData);
    }
  }, [reportsResponseData, showFilter]);

  const handleHome = () => {
    history.push("menu");
  };
  const handlePerformance = () => {
    setPerformance(!performance);
  };

  const handleTable = () => {
    setShowFilter(!showFilter);
  };
  const handleBackBtn = () => {
    backBtnRef.current.click();
  };

  const handleDownloadPDF = (Id, levelName, index) => {
    setLoadingIndex(index);
    setDownloadLoading(true);
    PdfReportsService.getPDFFile(bdUserId, Id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        setDownloadLoading(false);
        link.setAttribute("download", `${levelName}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviewPDF = (Id, levelName, index) => {
    setLoadingIndex(index);
    setPreviewLoading(true);
    PdfReportsService.getPDFFile(bdUserId, Id).then((response) => {
      setPdfUrl(response);
      setPdfFileName(levelName);
      setPreviewLoading(false);
      setShowPDF(true);
    });
  };

  /* Date converted to MM-DD-YYYY format */
  const formatDate = (value) => {
    let month = value.substring(5, 7);
    let date = value.substring(8, 10);
    let year = value.substring(0, 4);
    let mmddyy = month + "-" + date + "-" + year;
    return mmddyy;
  };

  useEffect(() => {
    const uniqeYrs = reportsResponseData.map((item) => {
      let date = new Date(item.createdDate);
      return date.getFullYear();
    });

    setUniqueYears([...new Set(uniqeYrs)]);
  }, [reportsResponseData]);

  return (
    <>
      <Header brandPrefix={brandPrefix} username={userName} />
      {!showPDF && (
        <div className="container">
          <div className="vault-img">
            <img src={vaultImages} width="20%" alt="vault" />
            <div className="home-btn">
              {!performance ? (
                <Button onClick={handleHome} class="btn-home" id="button">
                  Home
                </Button>
              ) : (
                <Button onClick={handleBackBtn} class="btn-home" id="button">
                  Back
                </Button>
              )}
            </div>
          </div>
          {!!loading && (
            <Accordion>
              <div className="card-horizontal">
                <div className="accordion-heading">
                  <MdOutlineFolderOpen className="icon" />
                  Duffy Household
                  <a href={props.location.state.boxURL} target="_blank" rel="noreferrer">
                    <BsBoxArrowUpRight className="duty-icon" />
                  </a>
                </div>
              </div>

              <Accordion.Item eventKey="1">
                <Accordion.Header onClick={handlePerformance}>
                  <CustomBackToggle backBtnRef={backBtnRef} eventKey="1" handleCollapse={handlePerformance} />

                  <span className="accordion-heading">
                    <MdOutlineFolderOpen className="icon" />
                    Performance Reports ({reportsResponseData && reportsResponseData.length})
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4 mb-3">
                        <div className="filter-heading" onClick={handleTable}>
                          <BsFilterLeft className="filter-icon" />
                          <h5>Filters</h5>
                        </div>

                        {showFilter && (
                          <div className="filter-container">
                            <div className="mr-3 d-flex">
                              Year
                              <Form.Select
                                aria-label="Default select"
                                onChange={(e) =>
                                  setFilterData({
                                    ...filterData,
                                    year: e?.target?.value,
                                  })
                                }
                              >
                                {uniqueYears &&
                                  uniqueYears.map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                              </Form.Select>
                            </div>
                            <div className="d-flex">
                              Quarter
                              <Form.Select
                                aria-label="Default select "
                                onChange={(e) =>
                                  setFilterData({
                                    ...filterData,
                                    quarter: e?.target?.value,
                                  })
                                }
                              >
                                <option value="q1">Q1</option>
                                <option value="q2">Q2</option>
                                <option value="q3">Q3</option>
                                <option value="q4">Q4</option>
                              </Form.Select>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="table">
                      <Table striped>
                        <thead>
                          <tr>
                            <th>{tableHeadings.name}</th>
                            <th>{tableHeadings.account}</th>
                            <th width="120">{tableHeadings.startdate}</th>
                            <th width="120">{tableHeadings.enddate}</th>
                            <th width="120">{tableHeadings.pageCount}</th>
                            <th width="120">{tableHeadings.dateposted}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPosts &&
                            currentPosts.map((item, index) => (
                              <tr>
                                <td>
                                  <BsFileEarmarkFill className="grid-icons mr-1" />
                                  {item.name}
                                </td>
                                <td>{item.levelName}</td>
                                <td>{formatDate(item.startDate)}</td>
                                <td>{formatDate(item.endDate)}</td>
                                <td>{item.pageCount}</td>
                                <td>{formatDate(item.createdDate)}</td>
                                <td>
                                  {(!previewLoading || loadingIndex !== index) && <BsFillEyeFill className="grid-icons pdfViwer" onClick={() => handlePreviewPDF(item.id, item.levelName, index)} />}
                                  {!!previewLoading && loadingIndex === index && <Spinner animation="border" size="sm" />}
                                </td>

                                <td>
                                  {(!downloadLoading || loadingIndex !== index) && <HiDownload onClick={() => handleDownloadPDF(item.id, item.levelName, index)} className="grid-icons pdfViwer" />}
                                  {!!downloadLoading && loadingIndex === index && <Spinner animation="border" size="sm" />}
                                </td>
                              </tr>
                            ))}
                          {/* {currentPosts.length < 0 && <div>No Reports Found</div>} */}
                        </tbody>
                      </Table>

                      <div className="pagination">
                        {reports.length > 0 && <Pagination totalPosts={reports && reports.length} postsPerPage={postsPerPage} paginate={paginate} view={5} showLast={false} showFirst={false} />}
                      </div>

                      {/* <div id="files-text">
                    <span class="files-per-text">Files per page</span>
                    <div class="horizontal-line">
                      <hr className="hr-line " />
                    </div>
                  </div> */}

                      {/* !********* Download popup code **********!  */}

                      {/* <div className="row">
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Title>
                            <div class="download-file-icon">
                              <span class="bi bi-file-earmark-fill " />
                            </div>
                            <div class="download-file-icon">
                              <span> Consector Adispacing</span>
                              <h6>Consector Adispacing elite consector, Adispacing tour</h6>
                            </div>
                          </Modal.Title>
                          <Modal.Body>
                            <div class="file-download-text">
                              <span> Your file has been downloaded.</span>
                            </div>
                          </Modal.Body>
                          <div class="modal-button">
                            <Button onClick={handleClose} class="btn-home" id="button">
                              Close
                            </Button>
                          </div>
                        </Modal>
                      </div> */}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="accordion-heading">
                <MdOutlineFolderOpen className="icon" />
                Custodian Document(25)
              </span>
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
              non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item> */}
            </Accordion>
          )}
          {!loading && (
            <div className="spinner-background">
              <div className="container spinner-background">
                <div className="mx-auto d-block justify-content-center text-center mt-4">
                  <h2>We're loading your reports...</h2>
                </div>
                <div className="mx-auto d-block justify-content-center text-center mt-3">
                  <img src={spinner} alt="spinner" />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showPDF && <PdfPreview setShowPDF={setShowPDF} pdfUrl={pdfUrl} pdfFileName={pdfFileName} />}
      <Footer />
    </>
  );
};
export default withOktaAuth(AccordionComponent);
