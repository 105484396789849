import React, { Component } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import axios from "axios";
import "./App.css";
import Login from "./components/login";
import Menu from "./components/menu";
import Error from "./components/error";
import NotFound from "./components/notfound";
import Welcome from "./components/welcome";
import CookiesInfo from "./components/cookiesInfo";
import ReactGA from "react-ga";
import Logout from "./components/logout";
import AccountActivationGuide from "./components/AccountActivationGuide";
import BrandLogo from "./components/BrandLogo";
import Legalterms from "./components/LegalTerms";
import AccordionComponent from "./components/blackDiamond/AccordionComponent";
import UnderMaintenance from "./components/UnderMaintenance";
class App extends Component {
  state = {
    background: "hightower",
    render: null,
    // activateMaintenance: process.env.REACT_APP_MAINTENANCE_AVAILABILITY,
  };
  constructor(props) {
    super(props);
    this.onAuthRequired = this.onAuthRequired.bind(this);
    this.restoreOriginalUri = this.restoreOriginalUri.bind(this);
    this.CheckMaintenance = this.CheckMaintenance.bind(this);
    this.redirectUri = window.location.origin + "/implicit/callback";
    this.config = {
      authUrl: process.env.REACT_APP_AUTH_BASE_URL,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      issuer: process.env.REACT_APP_AUTH_BASE_URL + "/oauth2/default",
      redirectUri: this.redirectUri,
      onAuthRequired: this.onAuthRequired,
    };
    this.oktaAuth = new OktaAuth(this.config);
    ReactGA.initialize("UA-000000-01");
  }
  async componentDidMount() {
    LogRocket.init("mgxe1z/well-th-view-portal", {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf("/authn") >= 0) {
            if (request && request.body) request.body = null;
          }
          if (request && request.headers["User-Agent"] && request.headers["User-Agent"].toLowerCase().includes("pingdom")) {
            request = null;
          }
          return request;
        },
      },
    });
    setupLogRocketReact(LogRocket);
    this.CheckMaintenance();
  }
  onAuthRequired() {
    this.props.history.push("/login");
  }
  async restoreOriginalUri(_oktaAuth, originalUri) {
    this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
  }
  setBackground = (brand) => {
    this.setState({ background: brand });
  };
  CheckMaintenance() {
    this.maintmode = false;
    if (!this.maintmode) {
      this.setState({ render: true });
    } else {
      const IPAddress = ["144.121.164.87", "172.58.164.198", "38.140.193.186", "76.141.242.118"];
      // const IPAddress = ["38.140.193.186", "72.255.1.215", "22.333.444.555", "33.444.555.666", "112.196.159.195"];
      axios.get("https://api.ipify.org?format=json").then((response) => {
        console.log(response.data.ip);
        this.setState({ render: IPAddress.indexOf(response.data.ip) !== -1 });
      });
    }
    console.log(this.state.render);
  }
  render() {
    return (
      <>
        {this.state.render ? (
          <React.Fragment>
            <Security oktaAuth={this.oktaAuth} onAuthRequired={this.config.onAuthRequired} restoreOriginalUri={this.restoreOriginalUri} tokenManager={{ storage: "sessionStorage" }}>
              <div className={`container-fluid full-height ${this.state.background}-background`}>
                <Switch>
                  <Route
                    path="/login"
                    render={(props) => (
                      <Login
                        authUrl={this.config.authUrl}
                        authClientId={this.config.clientId}
                        redirectUri={this.redirectUri}
                        issuer={this.config.issuer}
                        {...props}
                        setBackground={this.setBackground}
                      />
                    )}
                  />
                  <Route path="/brand-logo" exact render={(props) => <BrandLogo {...props} />} />
                  <SecureRoute path="/welcome" exact render={(props) => <Welcome authUrl={this.config.authUrl} {...props} />} />
                  <SecureRoute path="/menu" exact render={(props) => <Menu authUrl={this.config.authUrl} {...props} setBackground={this.setBackground} />} />
                  <SecureRoute path="/error" exact render={(props) => <Error {...props} />} />
                  <SecureRoute path="/logout" exact render={(props) => <Logout {...props} />} />
                  <SecureRoute path="/AccordionComponent" exact render={(props) => <AccordionComponent {...props} />} />
                  <Route path="/implicit/callback" exact component={LoginCallback} />
                  <Route path="/legalTerms" exact component={Legalterms} />
                  <Route path="/not-found" exact component={NotFound} />
                  <Route path="/legalTerms" exact component={Legalterms} />
                  <Route path="/cookies-info" exact component={CookiesInfo} />
                  <Route path="/user-guide" exact component={AccountActivationGuide} />
                  <Redirect from="/" to="/welcome" />
                  <Redirect exact to="/not-found" />
                  <Redirect exact to="/user-guide" />
                  <Redirect exact to="/brand-logo" />
                </Switch>
              </div>
            </Security>
          </React.Fragment>
        ) : (
          <UnderMaintenance />
        )}
      </>
    );
  }
}
export default withRouter(App);
