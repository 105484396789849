import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import UserService from "../services/userService";
import PortfolioRepostsService from "../services/PortfolioRepostsService";

const Portal = (props) => {
  const { userId } = props;
  const { ClientBoxUserActivated, BDPortalUserId } = props.userInfo;
  let logos = props.portal.LogoFile.split("/");
  var cardClass = `col col-md-${props.colWidth} card-padding`;
  const [url, setUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  const [reportcount, setReportcount] = useState();

  const history = useHistory();

  useEffect(() => {
    if (userId !== undefined) {
      UserService.getData(userId)
        .then((result) => {
          const bdportalID = result.BDPortalUserId;
          const bdID = result.Id;
          PortfolioRepostsService.getReports(bdportalID, bdID)
            .then((resData) => {
              setReportcount(resData.reportcount);
              setLoading(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  const navigateToAccordian = () => {
    const clientBox = ClientBoxUserActivated === "true" ? true : false;

    if ((clientBox || BDPortalUserId) && reportcount > 0) {
      logos[logos.length - 1].includes("vault")
        ? history.push({
            pathname: "/AccordionComponent",
            state: {
              boxURL: props.portal.URL,
            },
          })
        : setUrl(props.portal.URL);
    } else if (loading) {
      logos[logos.length - 1].includes("vault")
        ? window.open(props.portal.URL, "_blank")
        : history.push({
            pathname: "/AccordionComponent",
            state: {
              boxURL: props.portal.URL,
            },
          });
    }
  };

  console.log(url);
  return (
    <div className={cardClass}>
      {logos[logos.length - 1].includes("vault") ? (
        <div onClick={navigateToAccordian} className="decoration-none">
          <div className="card vendor c-pointer">
            <div className="portal-logo-container">
              {logos[logos.length - 1].split(".")[1] === "svg" ? (
                <img className="portal-logo" src={require(`../images/vendor-logos/${logos[logos.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
              ) : (
                <img className="portal-logo portal-logo-width" src={require(`../images/vendor-logos/${logos[logos.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
              )}
            </div>
            <div className="card-body w-100 border-top mt-4">
              <div className="">
                <ul className="pl-0">
                  {props.portal.Description.map((row, index) => (
                    <li key={index} className={`text-secondary text-decoration-none ${index}`}>
                      {row}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <a href={props.portal.URL} target="_blank" rel="noreferrer" className="decoration-none">
          <div className="card vendor">
            <div className="portal-logo-container">
              {logos[logos.length - 1].split(".")[1] === "svg" ? (
                <img className="portal-logo" src={require(`../images/vendor-logos/${logos[logos.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
              ) : (
                <img className="portal-logo portal-logo-width" src={require(`../images/vendor-logos/${logos[logos.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
              )}
            </div>
            <div className="card-body w-100 border-top mt-4">
              <div className="">
                <ul className="pl-0">
                  {props.portal.Description.map((row, index) => (
                    <li key={index} className={`text-secondary text-decoration-none ${index}`}>
                      {row}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </a>
      )}
    </div>
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default Portal;
